import FileFormField from './components/FileFormField';
import ImageFormField from './components/ImageFormField';
import TextareaFormField from './components/TextareaFormField';
import TextFormField from './components/TextFormField';

export default {
  FileFormField,
  ImageFormField,
  TextareaFormField,
  TextFormField
};
